import React from "react";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Typography from "@material-ui/core/Typography";
import { Asset } from "../interfaces/asset";
import { InlineAsset } from "../interfaces/inlineAsset";
import { heroStyles } from "../styles/inline";
import Button from "@material-ui/core/Button";
import { navigate } from "gatsby";
import TwoColumnCopy from '../components/TwoColumnCopy';
import Image from '../components/Img';
import Hero from "../components/Hero";

export const generateText = params => {
  const { type, children } = params;
  const hasType = type !== '';
  const isNotSentence = children.length > 1;
  if (hasType) return <Typography variant={type}>{children}</Typography>;
  // if (isNotSentence) {
  //   const output = children.map((item, index) => {
  //     const isString = typeof item === 'string';
  //     if (isString) return <Typography variant="body1" key={index}>{item}</Typography>
  //     return (
  //       <Typography variant="body1" key={index}>
  //         {item}
  //       </Typography>
  //     );
  //   });
  //   return <Typography variant="body1">{output}</Typography>;
  // }
  return <Typography variant="body1">{children}</Typography>;
  
};

export const renderAsset = (node: Asset) => {
  const { data, content, nodeType } = node;
  const target = data.target;
  const fields = target.fields;
  const { title, description, file } = fields;
  return (
    <div className="image">
      <Image title={title['en-US']} />
      <Typography variant="subtitle2" className="image__subtitle">{description["en-US"]}</Typography>
    </div>
  );
};

const isHeroBlock = args => {
  if (args === undefined) {
    return false;
  } else {
    const fields = args;
    const hasImage = fields.hasOwnProperty("backgroundImage");
    const hasCTA = fields.hasOwnProperty("callToActionText");
    const hasSubtitle = fields.hasOwnProperty("subtitle");
    const hasUrl = fields.hasOwnProperty("url");

    if (hasImage && hasCTA && hasSubtitle && hasUrl) {
      return true;
    }
  }

  return false;
};

const isTwoColumnCopyBlock = (args: any) => {
  if (args === undefined) {
    return false;
  } else {
    const fields = args;
    const hasColumnOne = fields.hasOwnProperty('columnOne');
    const hasColumnTwo = fields.hasOwnProperty('columnTwo');
    const hasChevron = fields.hasOwnProperty('chevron');

    if (hasColumnOne && hasColumnTwo && hasChevron) return true;
    return false;
  }
};

const isPageLink = (data: any) => {
  const typeExists = data.target.sys.hasOwnProperty('contentType');
  if (!typeExists) return false;
  if (typeExists) {
    const _type = data.target.sys.contentType.sys;
    const isHeroImage = _type.id === 'heroImage';
    const isPageLink = _type.type === 'Link' && _type.linkType === 'ContentType' && !isHeroImage;

    if (isPageLink) return true;
    return false;
  }
  return false;
}

export const renderInline = (node: InlineAsset) => {
  const { data, content, nodeType } = node;
  const fields = data.target.fields;
  const isHero = isHeroBlock(fields);
  const isTwoCol = isTwoColumnCopyBlock(fields);
  const isPgLink = isPageLink(data);
  if (isHero) {
    const {
      backgroundImage,
      callToActionText,
      displayCta,
      displaySubtitle,
      displayTitle,
      subtitle,
      title,
      url
    } = fields;
    const imgSrc = backgroundImage["en-US"].fields.file["en-US"].url;
    const ctaText = callToActionText["en-US"];
    const header = title["en-US"];
    const link = url["en-US"];
    const sub = subtitle["en-US"];
    const showCta = displayCta["en-US"];
    const showSub = displaySubtitle["en-US"];
    const showTitle = displayTitle["en-US"];
    return (
      <Hero
        imgSrc={imgSrc}
        ctaText={ctaText}
        header={header}
        link={link}
        sub={sub}
        showCta={showCta}
        showSub={showSub}
        showTitle={showTitle}
        backgroundImage={backgroundImage}
      />
    );
  }

  if (isTwoCol) {
    return <TwoColumnCopy fields={fields} />
  }

  if (isPgLink) {
    const label = fields.pageTitle['en-US'];
    return (
      <Button
        className="button--cta"
        variant="contained"
        color="primary"
        onClick={() => navigate(fields.permalink["en-US"])}
      >
        {label}
      </Button>
    );
  }

  return null;
};

export const options: any = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => {
      return generateText({ type: "h2", children });
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return generateText({ type: "h4", children });
    },
    [BLOCKS.HEADING_3]: (node, children) => {
      return generateText({ type: "h4", children });
    },
    [BLOCKS.HEADING_4]: (node, children) => {
      return generateText({ type: "h5", children });
    },
    [BLOCKS.HEADING_5]: (node, children) => {
      return generateText({ type: "h6", children });
    },
    [BLOCKS.HEADING_6]: (node, children) => {
      return generateText({ type: "h6", children });
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return generateText({ type: "", children });
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: Asset, children) => {
      return renderAsset(node);
    },
    [INLINES.EMBEDDED_ENTRY]: (node: any) => {
      return renderInline(node);
    },
    [INLINES.ENTRY_HYPERLINK]: (node: any) => {
      return renderInline(node);
    }
  }
};
