import React from "react";
import { Bar } from "react-chartjs-2";
import Typography from "@material-ui/core/Typography";

const BarChart = (props: any) => {
  const { title, width, options, data } = props;

  return (
    <div className="chart__body">
      <div className="chart__detail">
        <Typography variant="h4" className="chart__title">
          {title}
        </Typography>
      </div>
      <Bar
        data={data}
        width={width}
        height={width}
        options={options}
        redraw={true}
      />
    </div>
  );
};

export default BarChart;
