import React from "react";
import Img from "gatsby-image";
import { connect } from "react-redux";

const Image = (props: any) => {
  const { assets, title } = props;
  const results = assets.filter(item => item.node.title === title);
  const hasResults = results.length > 0;
  if (hasResults) {
    const _image = results[0];
    const { 
      contentful_id,
      description,
      fixed,
      fluid,
      id,
      title
     } = _image.node;
    return <Img 
      className="image__file" 
      fixed={fixed}
      alt={description}
    />;
  }
  return null;
}

const mapStateToProps = (state: any) => ({
  assets: state.components.assets
});

export default connect(mapStateToProps, null)(Image);