import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { styles } from "../styles/inline";
import CardResultTable from "./CardResultTable";
import Typography from "@material-ui/core/Typography";
import { calculateCombos } from "../state/actions/cards";
import {
  formatCombos,
  formatRadarData,
  getDisasterLevel,
  formatDoughnutData,
  cardDataCheck
} from "../functions/general";
import DeckSpaceRemaining from "./DeckSpaceRemaining";
import RadarChart from "./RadarChart";
import BarChart from "./BarChart";
import Loading from "../components/Loading";

class OptimizerResults extends Component<any, any> {
  componentWillMount() {
    this.props.calculateCombos({
      cardList: this.props.cardList,
      deckSize: this.props.deckSize,
      handFirst: this.props.handFirst
    });
  }
  render() {
    const cardList = this.props.cardList;
    const addNum = (a, b) => a + b;
    const _results = this.props.optimalResults.map(item => {
      const { name, count } = item;
      const isCard =
        name !== "feasible" && name !== "result" && name !== "bounded";
      const hasCount = count > 0;

      if (isCard && hasCount) {
        cardList.forEach(card => {
          const nameMatch = card.name.toLowerCase() === item.name.toLowerCase();
          if (nameMatch) {
            item.card = card;
            item.card = cardDataCheck(item.card);
            item.card.copies = item.count;
          }
        });
        return item;
      }

      return null;
    });

    const results = _results.filter(item => item !== null);
    const rawCards = results.map(item => item.card);

    let cardsComittedToCombos = results
      .map(item => {
        const card = item.card;
        const combos = card.combo;
        const hasCombos = Object.keys(combos).length > 0;
        const notExtra = card.extraDeck === false;

        if (hasCombos && notExtra) return item.count;
        return null;
      })
      .filter(item => item !== null);
    const hasComittedCombos = cardsComittedToCombos.length > 0;
    cardsComittedToCombos = hasComittedCombos
      ? cardsComittedToCombos.reduce(addNum)
      : 0;
    // .reduce(addNum);
    const chartOptions = {
      title: {
        display: false,
        text: "Combo Breakdown"
      },
      legend: {
        display: false
      }
    };

    const monsterCards = results
      .map(item => {
        const isMonster = item.card.category1.toLowerCase() === "monster";
        const notExtra = item.card.extraDeck === false;
        if (isMonster && notExtra) return item;
        return null;
      })
      .filter(item => item !== null);

    const spellCards = results
      .map(item => {
        const isSpell = item.card.category1.toLowerCase() === "spell";
        if (isSpell) return item;
        return null;
      })
      .filter(item => item !== null);

    const trapCards = results
      .map(item => {
        const isTrap = item.card.category1.toLowerCase() === "trap";
        if (isTrap) return item;
        return null;
      })
      .filter(item => item !== null);

    const extraCards = results
      .map(item => {
        const isExtra = item.card.extraDeck === true;
        if (isExtra) return item;
        return null;
      })
      .filter(item => item !== null);

    let combos: any[] = [];
    let hasCombos = false;
    let chartData: any;
    let disasterRating: number;
    const mainDeckCards = results.filter(item => {
      const card = item.card;
      const isMainDeck = card.extraDeck !== true;
      if (isMainDeck) return true;
      return false;
    });
    const mainDeckSize = mainDeckCards.map(card => card.count).reduce(addNum);
    const donughtChartData = formatDoughnutData({
      cardList: rawCards,
      deckSize: mainDeckSize
    });

    Object.keys(this.props.combos).forEach(key => {
      if (key !== "total") {
        combos.push({
          name: key,
          totalMin: this.props.combos[key].totalMin,
          totalMax: this.props.combos[key].totalMax
        });
      }
    });

    combos = formatCombos({ _combos: this.props.combos, cardList: results });
    if (combos.length > 0) {
      hasCombos = true;
      chartData = formatRadarData(combos);
      disasterRating =
        chartData.datasets[0].data.reduce(addNum) /
        chartData.datasets[0].data.length;
    }

    return (
      <div className="optimizer-results" style={styles.flexRow}>
        <div style={styles.flexItem}>
          <Typography variant="h4">Deck List</Typography>
          <div>
            <CardResultTable title={"Monster Cards"} cards={monsterCards} />
            <CardResultTable title={"Spell Cards"} cards={spellCards} />
            <CardResultTable title={"Trap Cards"} cards={trapCards} />
            <CardResultTable title={"Extra Deck"} cards={extraCards} />
          </div>
        </div>
        {this.props.calculatingProbs ? (
          <Loading title="Loading ..." />
        ) : (
          <div style={styles.flexItem}>
            {hasCombos ? (
              <div>
                <Typography variant="h4">Combos</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Probability</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {combos.map((combo, index) => (
                      <TableRow key={index}>
                        <TableCell>{combo.name}</TableCell>
                        <TableCell>
                          <span key={index}>{combo.actual.toFixed(2)}</span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {hasCombos ? (
                  <div className="optimizer-results__breakdown">
                    <DeckSpaceRemaining
                      value={
                        (cardsComittedToCombos / this.props.deckSize) * 100
                      }
                      title={`Non-Combo Cards Available In Main Deck: ${mainDeckSize -
                        cardsComittedToCombos}`}
                    />
                    <RadarChart
                      title="Combo Breakdown"
                      width={320}
                      options={chartOptions}
                      data={chartData}
                    />
                    {/* <Typography variant="h4">{`Opening Hand Disaster Level: ${getDisasterLevel(
                    disasterRating
                  )}`}</Typography> */}
                    <BarChart
                      title="Deck Breakdown"
                      width={320}
                      options={donughtChartData.options}
                      data={donughtChartData.data}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

// OptimizerResults.propTypes = {
//   combos: PropTypes.object.isRequired,
//   optimalResults: PropTypes.array.isRequired,
// }

const mapStateToProps = (state: any) => ({
  combos: state.cards.combos,
  optimalResults: state.cards.optimalResults,
  deckSize: state.cards.deckSize,
  cardList: state.cards.cardList,
  handFirst: state.cards.handFirst,
  calculatingProbs: state.cards.calculatingProbs
});

export default connect(
  mapStateToProps,
  { calculateCombos }
)(OptimizerResults);
