import React from "react";
import Typography from "@material-ui/core/Typography";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { options } from "../styles/TypographySettings";

export default (props: any) => {
  const fields = props.fields;
  const { chevron, columnOne, columnTwo, name, showTitle } = fields;
  const col1Text = documentToReactComponents(columnOne["en-US"], options);
  const col2Text = documentToReactComponents(columnTwo["en-US"], options);
  const title: string = name["en-US"];
  const hasChevron: boolean = chevron["en-US"];
  let featureTitle: boolean = false;
  const hasFeatureTitle = showTitle !== undefined;
  if (hasFeatureTitle) {
    featureTitle = showTitle["en-US"];
  }
  

  return (
    <div className="two-col">
      <div className="two-col__title">
        {hasChevron ? (
          <i className="material-icons two-col__chevron">chevron_right</i>
        ) : null}
        {featureTitle ? (
          <Typography variant="h4" className="two-col__title-text">
            {title}
          </Typography>
        ) : null}
      </div>

      <div className="two-col__cols">
        <div className="two-col__one">{col1Text}</div>
        <div className="two-col__two">{col2Text}</div>
      </div>
    </div>
  );
};
