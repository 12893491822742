import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const DeckSpaceRemaining = (props: any) => {
  const { value, title } = props;
  return (
    <div className="line">
      <Typography variant="h5">{title}</Typography>
      <LinearProgress
        className="line__progress"
        variant="determinate"
        value={Math.min(value)}
      />
    </div>
  );
};

export default DeckSpaceRemaining; 