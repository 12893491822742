import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/lab/Slider'
import Button from '@material-ui/core/Button'
import Tooltip from "@material-ui/core/Tooltip";
import ToolTipText from "./ToolTipText";
import { formatPendulumText } from './SearchResults';

const CardTable = (props: Props) => {
  const { cards, updateSlider, title, cardList, removeCard } = props
  return (
    <div className="card-table">
      <Typography variant="title" id="tableTitle">{title}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Min. Copies</TableCell>
            <TableCell>Max. Copies</TableCell>
            <TableCell>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cards.map((card: any) => {
            return (
              <TableRow key={card.id}>
                <TableCell>
                  <Tooltip
                    classes={{
                      tooltip: "tooltip",
                      popper: "tooltip__popper"
                    }}
                    className="tooltip"
                    title={
                      <ToolTipText
                        card={card}
                        formatPendulumText={formatPendulumText}
                      />
                    }
                  >
                    <div>{card.name}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography className="card-table__slider-label">
                    {card.minimum != 1
                      ? `${card.minimum} Copies`
                      : "1 Copy"}
                  </Typography>
                  <Slider
                    value={card.minimum}
                    onChange={(event, value) =>
                      updateSlider(
                        event,
                        (card.minimum = value),
                        card,
                        "minimum"
                      )
                    }
                    min={0}
                    max={3}
                    step={1}
                    id="cardMin"
                  />
                </TableCell>
                <TableCell>
                  <Typography className="card-table__slider-label">
                    {card.limit != 1
                      ? `${card.limit} Copies`
                      : "1 Copy"}
                  </Typography>
                  <Slider
                    value={card.limit}
                    onChange={(event, value) =>
                      updateSlider(
                        event,
                        (card.limit = value),
                        card,
                        "limit"
                      )
                    }
                    min={0}
                    max={3}
                    step={1}
                    id="cardMax"
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="raised"
                    onClick={() => removeCard(cardList, card)}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  )
}

export default CardTable;

interface Props {
  cardList: any
  cards: any
  updateSlider: any
  title: string
  removeCard: any
}