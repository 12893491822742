import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const CardResultTable = (props: any) => {
  const addNum = (a, b) => {
    return a + b;
  };
  const compare = (a, b) => {
    if (a.count > b.count) return -1;
    return 1;
  };
  const { cards, title } = props;
  const cardsExist = cards.length > 0;

  if (!cardsExist) {
    const total = 0;
    const output = cards.map(card => card).sort(compare);

    return (
      <div className="card-table">
        <Typography variant="title" id="tableTitle">
          {`${title}: ${total}`}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {output.map((item: any) => {
              const card = item.card;
              const hasCombos = Object.keys(card.combo).length > 0;
              return (
                <TableRow key={card.id}>
                  {hasCombos ? (
                    <TableCell>
                      <strong>{card.name}</strong>
                    </TableCell>
                  ) : (
                    <TableCell>{card.name}</TableCell>
                  )}
                  <TableCell>{item.count}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  if (cardsExist) {
    const total = cards.map(card => card.count).reduce(addNum);
    const output = cards.map(card => card).sort(compare);

    return (
      <div className="card-table">
        <Typography variant="title" id="tableTitle">
          {`${title}: ${total}`}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {output.map((item: any) => {
              const card = item.card;
              const hasCombos = Object.keys(card.combo).length > 0;
              return (
                <TableRow key={card.id}>
                  {hasCombos ? (
                    <TableCell>
                      <strong>{card.name}</strong>
                    </TableCell>
                  ) : (
                    <TableCell>{card.name}</TableCell>
                  )}
                  <TableCell>{item.count}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
};

export default CardResultTable;
