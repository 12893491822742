import React from "react";
import CardPool from "../appComponents/CardPool";
import { connect } from "react-redux";
import TabbedMenu from "../appComponents/TabbedMenu";
import OptimizerResults from "../appComponents/OptimizerResults";
import Layout from "../components/Layout";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Loading from "../components/Loading";
import Paper from "@material-ui/core/Paper";
import { Context } from "../interfaces/page";
import { options as config } from "../styles/TypographySettings";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { navigate } from "gatsby";
import firebase from "firebase/app";
import { authenticateUser } from "../state/actions/auth";

interface Optimizer {
  optimalResults: any[];
  optimizing: boolean;
  optimizerError: boolean;
  optimizerMsg: string;
  pageContext: Context;
  isActive: any;
  authenticateUser: any;
}

class Optimizer extends React.Component<Optimizer, any> {
  render() {
    const getCopy = args => {
      const { context, name } = args;
      const hasComponents = context.featuredComponents !== null;

      if (!hasComponents) {
        return null;
      }

      if (hasComponents) {
        const data = context.featuredComponents.filter(
          item => item.title === name
        )[0];
        const hasData = data !== undefined;
        if (!hasData) return null;
        if (hasData) return data.childContentfulAppComponentCopyRichTextNode.json;
      }

    };
    const context = this.props.pageContext;
    const {
      metaDescription,
      searchIndex,
      childContentfulPageCopyRichTextNode,
      featuredComponents
    } = context;
    const SEO = { searchIndex, metaDescription };
    const _cardPoolCopy: any = context.childContentfulPageCopyRichTextNode.json;
    const _cardSearchCopy: any = getCopy({ context, name: "Card Search" });
    const _combosCopy: any = getCopy({ context, name: "Combos" });
    const _loadSaveCopy: any = getCopy({ context, name: "Load / Save" });
    const _optimizerCopy: any = getCopy({ context, name: "Optimizer" });
    const cardPoolCopy = documentToReactComponents(_cardPoolCopy, config);
    const cardSearchCopy = documentToReactComponents(_cardSearchCopy, config);
    const combosCopy = documentToReactComponents(_combosCopy, config);
    const loadSaveCopy = documentToReactComponents(_loadSaveCopy, config);
    const optimizerCopy = documentToReactComponents(_optimizerCopy, config);
    const copies = {
      cardSearchCopy,
      combosCopy,
      loadSaveCopy,
      optimizerCopy
    };
    const options: any = [
      {
        label: "Card List",
        content: <CardPool copy={cardPoolCopy} />
      },
      {
        label: "Results",
        content: <OptimizerResults />
      }
    ];
    const content = (
      <Paper className="optimizer">
        {this.props.optimalResults.length <= 1 ? (
          <CardPool copy={cardPoolCopy} />
        ) : (
          <TabbedMenu options={options} />
        )}

        <Dialog open={this.props.optimizing}>
          <DialogContent>
            <Loading title={this.props.optimizerMsg} />
          </DialogContent>
        </Dialog>
      </Paper>
    );
    return (
      <Layout
        components={[{ columns: 12, content, className: "application" }]}
        authRequired={true}
        copies={copies}
        seo={SEO}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  optimalResults: state.cards.optimalResults,
  optimizing: state.cards.optimizing,
  optimizerError: state.cards.optimizerError,
  optimizerMsg: state.cards.optimizerMsg,
  isActive: state.auth.isActive
});

export default connect(
  mapStateToProps,
  { authenticateUser }
)(Optimizer);
