import React from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { heroStyles } from "../styles/inline";
import { navigate } from "gatsby";

const Hero = (props: any) => {
  const {
    assets,
    backgroundImage,
    ctaText,
    header,
    imgSrc,
    link,
    showCta,
    showSub,
    showTitle,
    sub
  } = props;
  const { fields, sys } = backgroundImage["en-US"];
  const title = fields.title["en-US"];
  const results = assets.filter(item => item.node.title === title);
  const hasResults = results.length > 0;
  if (hasResults) {
    const _image = results[0];
    const { contentful_id, description, fixed, fluid, id, title } = _image.node;
    return (
      <div className="hero">
        <div className="hero__image" style={heroStyles(fluid.srcWebp)}>
          <div className="hero__text">
            {showTitle ? (
              <Typography variant="h3" color="inherit">
                {header}
              </Typography>
            ) : null}
            {showSub ? <Typography color="inherit">{sub}</Typography> : null}
            {showCta ? (
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(link)}
              >
                {ctaText}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state: any) => ({
  assets: state.components.assets
});

export default connect(
  mapStateToProps,
  null
)(Hero);
