import React from "react";
import { connect } from 'react-redux'
import { updateCardAttribute, removeCard } from '../state/actions/cards'
import { styles } from '../styles/inline'
import CardTable from './CardTable'
import { classifyCardCategories } from '../functions/general'


class CardPool extends React.Component<any, any> {
  state = {
    cards: [],
  }

  componentDidMount() {
    this.setState({
      cards: this.props.cardList
    })
  }

  updateSlider = (event: any, value: any, card: any, attribute: any) => {
    const params = {
      card: card,
      value: value,
      cards: this.props.cardList,
      attribute: attribute
    }

    this.props.updateCardAttribute(params)

    this.setState({
      cards: this.props.cardList
    })

  }

  render() {
    const copy = this.props.copy;
    const cards = this.props.cardList
    const { hasCards, hasMonsters, monsters, hasSpells, spells, hasTraps, traps, hasExtra, extra } = classifyCardCategories(cards);
    if (hasCards) {
      return (
        <div className="card-pool">
          {hasMonsters ? (
            <CardTable
              cards={monsters}
              updateSlider={this.updateSlider}
              title={'Monsters'}
              cardList={cards}
              removeCard={this.props.removeCard}
            />
          ): null}
          {hasSpells ? (
            <CardTable
              cards={spells}
              updateSlider={this.updateSlider}
              title={'Spells'}
              cardList={cards}
              removeCard={this.props.removeCard}
            />
          ) : null}
          {hasTraps ? (
            <CardTable
              cards={traps}
              updateSlider={this.updateSlider}
              title={'Traps'}
              cardList={cards}
              removeCard={this.props.removeCard}
            />
          ) : null}
          {hasExtra ? (
            <CardTable
              cards={extra}
              updateSlider={this.updateSlider}
              title={'Extra Deck'}
              cardList={cards}
              removeCard={this.props.removeCard}
            />
          ) : null}
        </div>
      )
    } else {
      return (
        <div className="card-pool__copy">
          {copy}
        </div>
      )
    }
  }
}

const mapStateToProps = (state: any) => ({
  cardList: state.cards.cardList
})

export default connect(mapStateToProps, { updateCardAttribute, removeCard })(CardPool)